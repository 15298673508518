import { buildUrl } from '@/http/utils'
import { URLS } from '@router/index'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SignJWT, jwtVerify } from 'jose'
import { Base64 } from 'js-base64'
dayjs.locale('zh-cn')
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * 格式化时间
 */
export function formatDate(date, format = 'YYYY-MM-DD HH:mm') {
  return dayjs(date).tz('PRC').format(format)
}

/**
 * 判断用户是否有pointing设备，比如鼠标
 * @returns boolean
 */
export function hasPointingDevice() {
  return typeof matchMedia === 'function' && matchMedia('(pointer:fine)').matches
}

/**
 * 字符串转颜色
 * @returns hex
 */
export function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

/**
 * 阻止事件冒泡
 */
export function stopEvent(event) {
  event.stopPropagation()
}


/**
 * 获取新闻详情链接
 */
export function articleToSlug(data) {
  return buildUrl(URLS.article, { slug: Base64.encode(`${process.env.NEXT_PUBLIC_SLUG_KEY}${data.id}`) })
}

/**
 * 新闻详情slug转id
 */
export function slugToArticle(slug) {
  return Base64.decode(decodeURIComponent(slug)).replace(process.env.NEXT_PUBLIC_SLUG_KEY, '')
}

/**
 * 生成token
 * @returns {token, refreshToken}
 */
export async function generateToken(user) {

  const payload = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName
  }
  const iat = Math.floor(Date.now() / 1000)
  // 1小时过期
  const tokenExp = iat + 60 * 60;
  // 7天过期
  const refreshExp = iat + 60 * 60 * 24 * 7;

  const token = await new SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
    .setExpirationTime(tokenExp)
    .setIssuedAt(iat)
    .setNotBefore(iat)
    .sign(new TextEncoder().encode(process.env.JWT_SECRET))

  const refreshToken = await new SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
    .setExpirationTime(refreshExp)
    .setIssuedAt(iat)
    .setNotBefore(iat)
    .sign(new TextEncoder().encode(process.env.JWT_SECRET))

  return {
    token,
    refreshToken
  }
}

/**
 * 验证token
 * @returns boolean
 */
export async function verifyToken(token) {
  return jwtVerify(token, new TextEncoder().encode(process.env.JWT_SECRET))
}
