// 首页
export const homepage = '/'

// 公司简介
export const aboutUs = '/about-us'

// CRO服务
export const croConcept = '/cro/concept'
// CRO体系
export const croScheme = '/cro/scheme'

// 药效基地
export const drugBase = '/drug-base'
// 动物管理
export const animalManagement = '/animal-management'

// 招纳贤士
export const hiring = '/hiring'
// 校园招聘
export const hiringCampus = '/hiring/campus'
// 社会招聘
export const socialSocial = '/hiring/social'

// 新闻首页
export const news = '/news'
// 新闻详情
export const article = '/article/{slug}'
// 新闻预览
export const articlePreview = '/article-preview/{id}'


// =======================================ADMIN=======================================
// 登录
export const adminLogin = '/admin'

// admin首页
export const adminHomepage = '/admin/home'

// admin新闻咨询
export const adminNews = '/admin/news'

// admin招聘设置
export const adminHiring = '/admin/hiring'

// admin导航栏管理
export const adminNavs = '/admin/navs'
