'use client'

import { themeOptions } from '@/lib/theme'
import { ThemeProvider, createTheme } from '@mui/material'
import { RecoilRoot } from 'recoil'

const theme = createTheme(themeOptions)
export default function Root({
  children
}) {

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        {children}
      </RecoilRoot>
    </ThemeProvider>
  )
}
