'use client'

import { hasPointingDevice } from '@utils'
import clsx from 'clsx'
import { assign, get, map, size } from 'lodash-es'
import Link from 'next/link'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean } from 'react-use'

export default function NavMenu({
  children,
  popupProps = {}
}) {

  const [isTouchDevice, setIsTouchDevice] = useBoolean(false)
  useEffect(() => {
    setIsTouchDevice(!hasPointingDevice())
  }, [setIsTouchDevice])

  const [show, toggleShow] = useBoolean(false)
  const handleEnter = useCallback(() => {
    if (isTouchDevice) return
    toggleShow(true)
  }, [toggleShow, isTouchDevice])
  const handleLeave = useCallback(() => toggleShow(false), [toggleShow])
  const handleClick = useCallback(() => toggleShow(), [toggleShow])

  const menuProps = useMemo(
    () => assign(
      {},
      popupProps,
      {
        show,
        className: clsx('absolute top-full left-1/2 -translate-x-1/2', get(popupProps, 'className', '')),
        onClick: handleLeave
      }
    ),
    [show, popupProps, handleLeave]
  )

  return (
    <div className="relative group/btn" onMouseLeave={handleLeave}>
      <div onMouseEnter={handleEnter} onClick={handleClick} className="relative">
        {children}
        <i className="absolute bottom-0 w-full h-2 scale-x-0 bg-white rounded-md group-hover/btn:scale-x-100 transition-transform" />
      </div>
      <MenuPopup {...menuProps}></MenuPopup>
    </div>
  )
}

export function MenuPopup({
  className,
  links,
  show = false,
  onClick
}) {
  const selfRef = useRef(null)
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    if (!show || !selfRef.current) return
    // 防止菜单超出屏幕
    const rect = selfRef.current.getBoundingClientRect()
    if (rect.right > window.document.body.clientWidth) {
      setOffset(window.document.body.clientWidth - rect.right)
    } else if (rect.left < 0) {
      setOffset(Math.abs(rect.left))
    } else {
      setOffset(0)
    }
  }, [show, setOffset, selfRef])
  return size(links) > 0 && (
    <div className={show ? 'animate-expansion' : 'hidden'} onClick={onClick}>
      <div className={clsx('pt-8 max-w-screen', className)} ref={selfRef}>
        <i className="absolute z-[1] block w-0 h-0 -translate-y-full border-8 border-solid border-transparent border-b-white drop-shadow-menu-bottom left-1/2 -translate-x-1/2"></i>
        <div className="shadow-menu flex flex-col bg-white rounded-xs sm:rounded-sm" style={{ transform: `translateX(${offset}px)` }}>
          {
            size(links) > 0 && (
              <div className="py-10 flex flex-col">
                {
                  map(links, (item, idx) => (
                    <Link
                      className="px-16 py-10 text-sm hover:bg-primary-light hover:text-primary hover:font-bold transition-colors"
                      href={item.href}
                      key={idx}
                    >
                      {item.label}
                    </Link>
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
    </div >
  )
}