'use client'

import FadeIn from '@component/fadeIn'
import Img1 from '@image/hero/1.jpg'
import Img2 from '@image/hero/2.jpg'
import Img3 from '@image/hero/3.jpg'
import { map } from 'lodash-es'
import Image from 'next/image'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const data = [
  { image: Img1, title: '药代动力学研究及生物分析技术平台', text: '平台与Thermo Fisher、Waters、Sciex等国际知名仪器设备厂商以及联影科仪、谱聚医疗等国产高端分析仪器深度合作' },
  { image: Img2, title: '实验动物中心', text: '拥有相关动物实验的资质包括：屏障系统（大鼠、小鼠）；普通环境（犬、猴、实验用猪、兔、地鼠、豚鼠）' },
  { image: Img3, title: '体外研究技术平台', text: 'P2级细胞实验室、三级细胞库、STR鉴定室、基因工程实验室、基因检测室、细胞影像室' },
]

export default function Server() {
  return (
    <section className="section-wrap py-40 md:py-60 lg:py-96">
      <FadeIn>
        <Content></Content>
      </FadeIn>
    </section>
  )
}

function Content() {
  return (
    <>
      <div className="text-xl lg:text-[40px] lg:leading-46 font-medium">特色平台</div>
      <div className="mt-20 animate__animated animate-delay-400 animate__zoomIn">
        <Swiper
          spaceBetween={0}
          resistanceRatio={0}
          loop
          grabCursor
          autoplay={{
            delay: 4500,
            pauseOnMouseEnter: true
          }}
          modules={[Autoplay]}
        >
          {
            map(data, (item, idx) => (
              <SwiperSlide key={idx}>
                <SlideItem data={item} index={idx} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </>
  )
}

function SlideItem({
  data,
  index
}) {
  return (
    <div className='grid gap-y-10 lg:grid-cols-2 lg:gap-x-40 gap-x-70'>
      <div className="">
        <Image
          src={data.image}
          alt={data.title}
          priority
          unoptimized
          className="w-full h-200 lg:h-300 block rounded-xs rounded-tl-4xl rounded-br-4xl object-cover"
        />
      </div>
      <div className='flex flex-col pb-10'>
        <i className="text-orange-600 text-[80px] font-normal">{index + 1}</i>
        <h3 className="text-xl lg:text-6xl font-medium mt-auto">{data.title}</h3>
        <div className="text-sm lg:text-base mt-10 lg:mt-20 text-gray-700 !leading-normal">{data.text}</div>
      </div>
    </div>
  )
}
