'use client'

import clsx from 'clsx'
import IconClose from '@icon/close-bold.svg'
import { useLockBodyScroll } from 'react-use'

export default function FullPopup({
  show = false,
  className = '',
  bgClick,
  children
}) {

  useLockBodyScroll(show)
  return (
    <div
      onClick={bgClick}
      className={clsx(
        'fixed inset-0 z-50 bg-black bg-opacity-40 backdrop-blur-sm transition-all',
        show ? ' opacity-100 visible' : 'opacity-0 invisible pointer-events-none',
        className
      )}
    >
      {children}
    </div>
  )
}

export function CloseButton({
  onClick,
  className = ''
}) {
  return (
    <button
      className={className}
      aria-label="close"
      onClick={onClick}
    >
      <IconClose className="w-24 h-24 fill-gray-700 block" />
    </button>
  )
}