'use client'

import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'

export default function HeaderWrap({
  float = false,
  children
}) {
  const [isScrolled, setIsScrolled] = useState(false)
  const { y } = useWindowScroll()

  useEffect(() => {
    setIsScrolled(float && y >= 50)
  }, [setIsScrolled, y, float])

  return (
    <header
      className={clsx(
        { 'bg-white': !float },
        { 'bg-black bg-opacity-0 fixed left-0 top-0 w-full z-30 transition-colors duration-300': float },
        { '!bg-opacity-40 backdrop-blur-md': isScrolled },
      )}
    >
      <div className="section-wrap">
        <section className={clsx('flex items-center gap-x-10 py-10 md:py-0', { 'border-b border-opacity-50': !isScrolled })}>
          {children}
        </section>
      </div>
    </header>
  )
}
