'use client'

import { aboutLinks, contactLinks, croLinks, facilityLinks, newsLinks, platformLinks } from '@/lib/config'
import AppendRoot from '@component/appendRoot'
import FullPopup, { CloseButton } from '@component/fullPopup'
import IconDown from '@icon/arrow-down.svg'
import { URLS } from '@router/index'
import { stopEvent } from '@utils'
import clsx from 'clsx'
import { get, map, size } from 'lodash-es'
import Link from 'next/link'
import { Fragment, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useBoolean } from 'react-use'

const HeaderPopupContext = createContext()
function useHeaderPopup() {
  return useContext(HeaderPopupContext)
}

export default function HeaderPopup({
  data
}) {
  const { showPoup, handleToggle } = useHeaderPopup()
  return (
    <FullPopup
      className="flex justify-end md:hidden"
      show={showPoup}
      bgClick={handleToggle}
    >
      <div className={clsx('w-3/4 h-full flex flex-col bg-white relative pt-34 transition-transform', showPoup ? '' : 'translate-x-full')} onClick={stopEvent}>
        <CloseButton className="absolute right-10 top-4" onClick={handleToggle} />
        {
          showPoup && (
            <div className="flex-1 min-h-0 overflow-auto bg-gray-600 text-white py-20" onTouchMove={stopEvent}>
              {
                map(data, (nav) => (
                  <NavItem
                    key={nav.id}
                    href={nav.url}
                    links={map(nav.children, item => ({ label: item.title, href: item.url }))}
                  >
                    {nav.title}
                  </NavItem>
                ))
              }
            </div>
          )
        }
      </div>
    </FullPopup>
  )
}

function NavItem({
  children,
  links,
  href
}) {
  const { handleToggle } = useHeaderPopup()
  const Tag = href ? Link : 'span'
  const [showChild, toggleShow] = useBoolean(false)
  const toggleChild = useCallback(() => {
    if (size(links) > 0) toggleShow()
  }, [toggleShow, links])

  const childRef = useRef(null)
  const [childHeight, setChildHeight] = useState(0)
  useEffect(() => {
    if (childRef.current) {
      setChildHeight(showChild ? childRef.current.offsetHeight : 0)
    }
  }, [childRef, setChildHeight, showChild])

  return (
    <div>
      <div className={clsx('flex border-b border-b-white border-opacity-30 mx-16', { '!border-b-transparent': showChild })}>
        <Tag href={href} className="flex-1 min-w-0 line-clamp-1 text-base text-white py-14" onClick={href ? handleToggle : undefined}>{children}</Tag>
        {
          size(links) > 0 && (
            <button type="button" className="px-10 -mr-10" onClick={toggleChild}>
              <IconDown className={clsx('w-16 h-16 fill-white transition-transform', { '-scale-y-100': showChild })} />
            </button>
          )
        }
      </div>
      {
        size(links) > 0 && (
          <div className="overflow-hidden transition-all" style={{ height: childHeight }}>
            <div className="bg-primary-light text-black px-16" ref={childRef}>
              {
                map(links, (item, i) => (
                  <div key={i} className="flex py-10 border-t border-t-primary border-opacity-40 first:border-t-0">
                    <Link href={item.href} className="flex-1 min-w-0 line-clamp-1 text-sm font-normal" onClick={handleToggle}>{item.label}</Link>
                    <IconDown className={clsx('w-12 h-12 fill-black -rotate-90')} />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export function MenuButton({
  data
}) {
  const { showPoup, handleToggle } = useHeaderPopup()
  return (
    <Fragment>
      <button
        className={clsx('h-16 w-26 menu-btn', { 'open': showPoup })}
        aria-label="menu"
        onClick={handleToggle}
      >
        <i />
      </button>
      <AppendRoot>
        <HeaderPopup data={data} />
      </AppendRoot>
    </Fragment>
  )
}

export function HeaderPopupProvider({
  children
}) {
  const [showPoup, toggleShowPopup] = useBoolean(false)
  const handleToggle = useCallback(() => toggleShowPopup(), [toggleShowPopup])

  return (
    <HeaderPopupContext.Provider value={{ showPoup, handleToggle }}>
      {children}
    </HeaderPopupContext.Provider>
  )
}
