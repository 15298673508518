'use client'

import clsx from 'clsx'
import { useContext } from 'react'
import { useMemo } from 'react'
import { createContext } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'

const FadeInContext = createContext()
export function useFadeInContext() {
  return useContext(FadeInContext)
}

export default function FadeIn({
  children
}) {
  const selfRef = useRef(null)
  const [show, setShow] = useState(false)
  const [disable, setDisable] = useState(false)

  const { x, y } = useWindowScroll()
  const { width, height } = useWindowSize()

  // 判断children是否在视窗上面
  // 1. 获取children的位置
  // 2. 获取视窗的位置
  // 3. 判断children的位置是否在视窗的位置内
  useEffect(() => {
    if (show || disable) return

    const rect = selfRef.current.getBoundingClientRect()
    if (rect.top + rect.height < 0) {
      setDisable(true)
      return
    }
    if (rect.top > 0 && rect.top <= height) {
      setShow(true)
    }
  }, [x, y, width, height, setShow, show, setDisable, disable])

  const state = useMemo(() => ({ visible: disable || show }), [disable, show])
  return (
    <FadeInContext.Provider value={state}>
      <div className={clsx('animate__animated animate-delay-300', { 'opacity-0': !show && !disable }, { 'animation-blockFadeIn': show })} ref={selfRef}>
        {
          children
        }
      </div>
    </FadeInContext.Provider>
  )
}