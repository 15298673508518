export const themeOptions = {
  palette: {
    mode: 'light',
    divider: '#FF8500',
    primary: {
      main: '#0092E4',
    },
    secondary: {
      main: '#f50057',
    }
  },
  typography: {
    fontSize: 1.4,
  },
}
