import { URLS } from '@router/index'

export const TOKEN_KEY = 'haifeng-token'
export const REFRESH_TOKEN_KEY = 'haifeng-refresh-token'

export const HIRING_TYPE = {
  1: '校园招聘',
  2: '社会招聘'
}

export const aboutLinks = [
  { label: '公司简介', href: URLS.aboutUs },
  { label: '发展历程', href: URLS.homepage },
  { label: '企业文化', href: URLS.homepage },
  { label: '荣誉资质', href: URLS.homepage },
  { label: '合作伙伴', href: URLS.homepage },
]
export const facilityLinks = [
  { label: '药效基地', href: URLS.drugBase },
  { label: '实验动物管理', href: URLS.animalManagement },
]
export const croLinks = [
  { label: '服务理念', href: URLS.croConcept },
  { label: '服务体系', href: URLS.croScheme },
]
export const platformLinks = [
  { label: '关键技术平台', href: URLS.homepage },
  { label: '公共技术服务中心', href: URLS.homepage },
]
export const newsLinks = [
  { label: '公司动态', href: URLS.homepage },
  { label: '行业资讯', href: URLS.homepage },
]
export const contactLinks = [
  { label: '业务合作', href: URLS.homepage },
  { label: '招纳贤士', href: URLS.homepage },
]
