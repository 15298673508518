import JsSHA from 'jssha'

export function getApiUrl(url) {
  if (/^https?/i.test(url)) return url
  return `${process.env.NEXT_PUBLIC_DOMAIN}/api/${url}`.replace(/\/\/\/?/g, '/').replace(/(https?:)\/([a-z0-9])/i, '$1//$2')
}

export function getApiHeaders(url, token) {
  const timestamp = process.env.FETCH_TIMESTAMP || Date.now()
  const headers = new Headers()
  headers.append('Timestamp', timestamp)
  headers.append('Sign', signUrl(url, timestamp))
  headers.append('Content-Type', 'application/json')
  if (token) {
    headers.append('Authorization', token)
  }
  return headers
}

export function buildUrl(url, params, query, hash) {
  if (!url) return url

  const originHash = `${url}`.includes('#') ? url.replace(/[^#]+(#.*)/, '$1') : null
  if (params) {
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        url = url.replace(`{${key}}`, params[key])
      }
    }
  }
  if (query) {
    let list = []
    for (const key in query) {
      if (Object.hasOwnProperty.call(query, key)) {
        list.push([key, query[key]].join('='))
      }
    }
    if (list.length > 0) url = `${url.replace(/#.*$/, '')}${url.indexOf('?') === -1 ? '?' : '&'}${list.join('&')}`
    if (originHash) url = `${url}${originHash}`
  }
  if (hash) url = originHash ? url.replace(/#.*$/, `#${hash}`) : `${url}#${hash}`
  return url
}

export function filterParams(params) {
  if (params instanceof FormData || params instanceof Array) return params

  const res = {}
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const value = params[key]
      if (![null, undefined, NaN].includes(value)) {
        res[key] = value
      }
    }
  }
  return res
}

export function signUrl(url, timestamp) {
  url = `${url}`.split('')
  let reg = /[a-z0-9]/i
  let urlMap = {}
  let urlList = []

  url.forEach(item => {
    if (reg.test(item)) {
      if (urlMap[item]) {
        urlMap[item]++
      } else {
        urlMap[item] = 1
        urlList.push(item)
      }
    }
  })

  let str = ''
  urlList.forEach(key => {
    str += `${key}${urlMap[key]}`
  })
  if (timestamp) {
    str += timestamp
  } else {
    str += new Date().getTime()
  }
  const shaObj = new JsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' })
  shaObj.update(str)
  return shaObj.getHash('HEX')
}
